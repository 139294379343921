import React, { Component } from 'react';
import axios from 'axios';
import {
    Add,
    Close,
    PlayArrow,
    Stop,
    CrisisAlert,
    AssistWalker,
    ContentCopy
  } from '@mui/icons-material';
import { Footer, Tabs } from '../universalComponents';
import dayjs from 'dayjs';
import {
  TableContainer, Table, TableHead, TableRow, TableCell,
  TableBody, Paper, TablePagination, IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions, Tooltip,
} from '@mui/material';
import BotForm from './components/botForm';
import BotResults from './botResults';

const KADENTRADING_API_URL = 'https://api.kadentrading.com/api';

export default class Bots extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      limit: 10,
      skip: 0,
      sort: 'createdAt',
      direction: 'decending',
      bots: [],
      loading: false,
      search: null,
      filters: [],
      totalDocs: 0,
      newBotDialog: false,
      token: null,
      copiedBot: null
    };
  }

  checkNewBot = (value) => {
    this.setState({ bots: [value, ...this.state.bots] })
  }
  checkBotUpdate = (value) => {
    const botIDs = this.state.bots.map(bot => bot._id);
    const botIdx = botIDs.indexOf(value._id);
    if (botIdx >= 0) {
      const newArray = this.state.bots;
      newArray[botIdx] = value;
      this.setState({ bots: newArray });
    }
  }
  grabBots = async (filters, search, skipVal, sortVal, directionVal) => {
    try {
      const { limit, skip, sort, direction } = this.state;

      let url = `${KADENTRADING_API_URL}/bots?limit=${limit}&skip=${skipVal ? skipVal : skip}&sort=${sortVal ? sortVal : sort}&direction=${directionVal ? directionVal : direction}`;
      if (this.state.search || search) url = url + `&search=${this.state.search || search}`;
      if (filters) {
        filters.forEach(fil => {
          url = url + `&${fil.key}=${fil.val}`;
        })
      } else if (this.state.filters.length) {
        this.state.filters.forEach(fil => {
          url = url + `&${fil.key}=${fil.val}`;
        })
      }

      const { data } = await axios.get(url);
      this.setState({ bots: data.results, skip: skipVal ? Number(skipVal) : this.state.skip, loading: false, totalDocs: data.totalResults });
    } catch (err) {
      console.log(err);
    }
  }
  componentDidMount() {
    const { updateCurrentTab, mobile } = this.props;
  
    if (!mobile) updateCurrentTab(4);
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      if (c.includes('user=')) {
        const obj = c.replace('user=', '').trim();
        const userObj = JSON.parse(obj);
        
        this.setState({ token: userObj.token })
      }
    }
    
    this.grabBots();
  }

  render() {
    const { currentTab, mobile } = this.props;

    const handleChangePage = (event, newPage) => {
      this.grabBots(null, null, JSON.stringify(newPage));
    };
    const toggleCreateBot = (bool) => {
      this.setState({ newBotDialog: bool });
      if (this.state.copiedBot && !bool) this.setState({ copiedBot: null });
    }
    const toggleBotStatus = async (currentStatus, botID) => {
      try {
        let status = 'just hanging';
        if (currentStatus === 'just hanging') status = 'patiently waiting';
      
        await axios.put(`${KADENTRADING_API_URL}/bots/updateBotStatus`, { status, botID }, { headers: { 'Authorization': `Basic ${this.state.token}` } });
      } catch(err) {
        console.log(err)
      }
    }
    const capitalize = (t) => t.replace(/(^|\s)[a-z]/g, (a) => a.toUpperCase());
    const copyBot = (value) => {
      this.setState({ copiedBot: value })
      toggleCreateBot(true);
    }
    const archiveBot = async (botID) => {
      try {
        await axios.put(`${KADENTRADING_API_URL}/bots/archiveBot`, { botID }, { headers: { 'Authorization': `Basic ${this.state.token}` } });
      } catch(err) {
        console.log(err)
      }
    }
    return (
      <div style={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
        {/* Navigation */}
        <div style={{ flex: '0 1 auto' }}>
          <Tabs currentTab={currentTab} mobile={mobile} />
        </div>
        {/* Main Content */}
        <div style={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ width: '80vw', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Tooltip placement='top' title={<div style={{ textAlign: 'center', fontSize: '10pt' }}>
              New Bot
            </div>}>
              <IconButton
                onClick={() => {toggleCreateBot(true)}}
                sx={{
                  '&:hover': { backgroundColor: 'rgba(13,149,242,0.5)' },
                  marginTop: 'auto', marginBottom: 'auto', color: 'white', backgroundColor: '#0D95F2'
                }}
                variant='contained'
              >
                <Add />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ width: '80vw', marginTop: '3vh', marginBottom: '3vh' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding='checkbox' />
                    <TableCell style={{ width: '20%' }}>Product</TableCell>
                    <TableCell align='left'>Status</TableCell>
                    <TableCell align='left'>Result Count</TableCell>
                    <TableCell align='left'>Min Price</TableCell>
                    <TableCell align='left'>Max Price</TableCell>
                    <TableCell align='left'>Max Quantity</TableCell>
                    <TableCell align='left'>Error</TableCell>
                    <TableCell align='left'>Updated At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.bots.map((row) => (
                    <TableRow key={row.product}>
                      {
                        row.status !== 'retired' ?
                        <TableCell align='left'>
                          <Tooltip placement='top' title={<div style={{ textAlign: 'center', fontSize: '10pt' }}>
                            { row.status === 'wasted' ? 'broke' : row.status === 'just hanging' ? 'Run dat shit' : 'Stop' }
                          </div>}>
                            <span>
                              <IconButton disabled={row.status === 'wasted'} onClick={() => toggleBotStatus(row.status, row._id)}>
                                {
                                  row.status === 'wasted' ?
                                  <CrisisAlert color='error' /> :
                                  row.status === 'just hanging' ?
                                  <PlayArrow color='primary' /> :
                                  <Stop color='primary' />
                                }
                              </IconButton>
                            </span>
                          </Tooltip>
                        </TableCell> :
                        <TableCell align='left'>
                          <Tooltip placement='top' title={<div style={{ textAlign: 'center', fontSize: '10pt' }}>
                            { 'Copy Bot' }
                          </div>}>
                            <span>
                              <IconButton onClick={() => copyBot(row)}>
                                <ContentCopy color='primary' />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </TableCell>
                      }
                      <TableCell align='left'>{capitalize(row.product) || '--'}</TableCell>
                      <TableCell align='left'>{capitalize(row.status) || 'broken'}</TableCell>
                      <TableCell align='left'>{row.results || '0'}</TableCell>
                      <TableCell align='left'>${row.minPrice || '0'}</TableCell>
                      <TableCell align='left'>${row.maxPrice}</TableCell>
                      <TableCell align='left'>{row.maxQuantity || '--'}</TableCell>
                      <TableCell align='left'>{row.errorMessage || '--'}</TableCell>
                      <TableCell align='left'>{row.updatedAt ? dayjs(row.updatedAt).format('MM/DD/YY, HH:mm') : '--'}</TableCell>
                      <TableCell align='left'>
                          <Tooltip placement='top' title={<div style={{ textAlign: 'center', fontSize: '10pt' }}>
                            { row.status !== 'retired' ? 'Retire Bot' : 'Happily Retired' }
                          </div>}>
                            <span>
                              <IconButton disabled={row.status === 'retired'} onClick={() => archiveBot(row._id)}>
                                <AssistWalker color='error' />
                              </IconButton>
                            </span>
                          </Tooltip>
                      </TableCell>
                      <TableCell align='right'>
                      <BotResults
                        row={row}
                      />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component='div'
              count={this.state.totalDocs}
              rowsPerPage={this.state.limit}
              page={this.state.skip}
              onPageChange={handleChangePage}
            />
          </div>
        </div>
        {/* Footer */}
        <div style={{ flex: '0 1 auto' }}><Footer /></div>
        <Dialog open={this.state.newBotDialog} onClose={() => {toggleCreateBot(false)}} fullWidth maxWidth='lg'>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <DialogTitle>New Bot</DialogTitle>
            <Tooltip placement='top' title={<div style={{ textAlign: 'center', fontSize: '10pt' }}>
              Close
            </div>}>
              <IconButton
                onClick={() => {toggleCreateBot(false)}}
                sx={{ '&:hover': { backgroundColor: 'rgba(0,0,0,0.2)' }, marginTop: 'auto', marginBottom: 'auto', color: '#0D95F2' }}
                variant='contained'
              >
                <Close />
              </IconButton>
            </Tooltip>
          </DialogActions>
          <DialogContent>
            <BotForm mobile={mobile} token={this.state.token} copiedBot={this.state.copiedBot} closeForm={() => {toggleCreateBot(false)}} />
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}
