const fn = () => {
    const openLocalSlug = (url) => window.location.replace(url);
    const openInNewTab = (url) => window.open(url, '_blank');

    return {
        openLocalSlug,
        openInNewTab,
    };
};

export default fn;
