import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    Tooltip,
    IconButton,
    Button,
    DialogContent,
} from '@mui/material';
import { Close, OpenInNew } from '@mui/icons-material';
import axios from 'axios';
import BotResultsComponent from './components/botResultsDialog';

const KADENTRADING_API_URL = 'https://api.kadentrading.com/api';

const ExpandedRowComponent = ({ row }) => {
    const [viewResultsDialog, toggleViewResults] = useState(false);
    const [botResults, setActiveResults] = useState([]);

    const checkResults = async () => {
      const { data } = await axios.get(`${KADENTRADING_API_URL}/botResults?limit=10000&skip=0&sort=createdAt&direction=decending&id=${row?._id}`);
      setActiveResults(data?.results || []);
      toggleViewResults(true);
    }

    return (
            <>
            <Button onClick={checkResults} variant='outlined' startIcon={<OpenInNew />} style={{ marginRight: '20px' }}>
              Purchases
            </Button>
            <Dialog open={viewResultsDialog} onClose={() => { toggleViewResults(false); } } fullWidth maxWidth='lg'>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                  <DialogTitle>Bot Results for "{row?.product}" | {row?.results || 0} purchases</DialogTitle>
                  <Tooltip placement='top' title={<div style={{ textAlign: 'center', fontSize: '10pt' }}>
                    Close
                  </div>}>
                    <IconButton
                      onClick={() => { toggleViewResults(false); } }
                      sx={{ '&:hover': { backgroundColor: 'rgba(0,0,0,0.2)' }, marginTop: 'auto', marginBottom: 'auto', color: '#0D95F2' }}
                      variant='contained'
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </DialogActions>
                <DialogContent>
                  <BotResultsComponent botResults={botResults} botID={row?._id} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ExpandedRowComponent;