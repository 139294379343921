import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './components/LandingPage';
import Products from './components/ProductsPage';
import Deals from './components/DealsPage';
import Login from './components/LoginPage';
import Users from './components/UsersPage';
import EbayBots from './components/EbayBotPage';
import BotResults from './components/BotResultsPage';
import LoadingSplash from './components/LoadingSplashPage';
import quickLinks from './utils/quickLinks';
const { openLocalSlug } = quickLinks();

export default class App extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      mobile: false,
      currentTab: 0,
      loading: true
    };
  }

  componentDidMount() {
    this.setState({ mobile: window.innerWidth <= 640 });
    
    const pathname = window.location.pathname;
    if (pathname === '/login') {
      this.setState({ loading: false });
    } else {
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      const userCheck = ca.filter(cookie => cookie.includes('user='));
      if (userCheck.length === 0) {
        openLocalSlug('/login');
      } else {
        this.setState({ loading: false });
      }
    }
  }

  render() {
    const updateCurrentTab = (tabIndex) => {
      this.setState({ currentTab: tabIndex });
    }

    return (
      <div className='App'>
        {/* Graphics */}
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          {
            this.state.currentTab === 0 && !this.state.mobile && !this.state.loading ?
            <img alt='swirl' style={{ height: '80vh', width: '32vw' }} src={require('./assets/swirl.png')} />
            : null
          }
        </div>
        {/* Routes */}
        <Router>
          <Routes>
            <Route exact path='/' element={
              this.state.loading ?
              <LoadingSplash />
              :
              <LandingPage mobile={this.state.mobile} currentTab={this.state.mobile ? 0 : this.state.currentTab} updateCurrentTab={updateCurrentTab} />
            } />
            <Route exact path='products' element={
              this.state.loading ?
              <LoadingSplash />
              :
              <Products mobile={this.state.mobile} currentTab={this.state.mobile ? 0 : this.state.currentTab} updateCurrentTab={updateCurrentTab} />
            } />
            <Route exact path='deals' element={
              this.state.loading ?
              <LoadingSplash />
              :
              <Deals mobile={this.state.mobile} currentTab={this.state.mobile ? 0 : this.state.currentTab} updateCurrentTab={updateCurrentTab} />
            } />
            <Route exact path='users' element={
              this.state.loading ?
              <LoadingSplash />
              :
              <Users mobile={this.state.mobile} currentTab={this.state.mobile ? 0 : this.state.currentTab} updateCurrentTab={updateCurrentTab} />
            } />
            <Route exact path='bots' element={
              this.state.loading ?
              <LoadingSplash />
              :
              <EbayBots mobile={this.state.mobile} currentTab={this.state.mobile ? 0 : this.state.currentTab} updateCurrentTab={updateCurrentTab} />
            } />
            <Route exact path='results' element={
              this.state.loading ?
              <LoadingSplash />
              :
              <BotResults mobile={this.state.mobile} currentTab={this.state.mobile ? 0 : this.state.currentTab} updateCurrentTab={updateCurrentTab} />
            } />
            <Route exact path='login' element={
              <Login mobile={this.state.mobile} />
            } />
          </Routes>
        </Router>
      </div>
    );
  }
}
