import { TableCell, Stack, IconButton, Tooltip } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import quickLinks from '../../../utils/quickLinks';

const ExpandedRowComponent = ({ row }) => {
    const { openInNewTab } = quickLinks();
    return (
        <TableCell colSpan='5' sx={{ background: 'rgba(0,0,0,0.1)' }}>
          <Stack direction={{ sm: 'row' }} sx={{ marginBottom: '5px', justifyContent: 'space-between' }} spacing={2}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>${row.price.flat?.toFixed(2) || '--'}</span>
                  <span style={{ color: 'rgba(0,0,0,0.5)' }}>Flat Price</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>${row.price.shipping?.toFixed(2) || '--'}</span>
                  <span style={{ color: 'rgba(0,0,0,0.5)' }}>Shipping Price</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{row.bestOffer ? 'Yes' : 'No' }</span>
                  <span style={{ color: 'rgba(0,0,0,0.5)' }}>Best Offer</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{row.country}</span>
                  <span style={{ color: 'rgba(0,0,0,0.5)' }}>Country</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                  <span>{row.seller.sellerName || '--'}</span>
                    {
                        row.seller.sellerName ?
                        <Tooltip placement='top' title={<div style={{ textAlign: 'center', fontSize: '10pt' }}>
                          Open Seller Page
                        </div>}>
                            <IconButton onClick={() => openInNewTab(`https://www.ebay.com/usr/${row.seller.sellerName}`)} sx={{ padding: '2px' }}>
                              <OpenInNew sx={{ padding: '2px', width: '10px', height: '10px' }} />
                            </IconButton>
                        </Tooltip>
                        : null
                    }
                  </div>
                  <span style={{ color: 'rgba(0,0,0,0.5)' }}>Seller</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{row.seller.sellerRating || '--'}</span>
                  <span style={{ color: 'rgba(0,0,0,0.5)' }}>Seller Rating</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{row.itemID}</span>
                  <span style={{ color: 'rgba(0,0,0,0.5)' }}>Ebay ID</span>
              </div>
          </Stack>
        </TableCell>
    );
};

export default ExpandedRowComponent;