import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Button,
    Stack,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from '@mui/material';

const KADENTRADING_API_URL = 'https://api.kadentrading.com/api';

const NewBotForm = (props) => {
    // const { token, mobile, closeForm } = props;
    const { token, closeForm, copiedBot } = props;

    const [product, setProduct] = useState('');
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [maxTotal, setMaxTotal] = useState(0);
    const [maxQuantity, setMaxQuantity] = useState(0);

    useEffect(() => {
        if (copiedBot) {
            if (copiedBot.product) setProduct(copiedBot.product);
            if (copiedBot.minPrice) setMinPrice(copiedBot.minPrice);
            if (copiedBot.maxPrice) setMaxPrice(copiedBot.maxPrice);
            if (copiedBot.maxTotal) setMaxTotal(copiedBot.maxTotal);
            if (copiedBot.maxQuantity) setMaxQuantity(copiedBot.maxQuantity);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [loading, toggleLoading] = useState(false);
    const [loadingDialog, toggleLoadingDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContent, setDialogContent] = useState('');

    const createNewBot = async () => {
        try {
            // "bannedPhrases" was removed from below
            const newBot = {
                product, maxPrice, minPrice, maxTotal, maxQuantity,
            };
            await axios.post(`${KADENTRADING_API_URL}/bot`, newBot, { headers: { 'Authorization': `Basic ${token}` } });

            setDialogTitle('Congrats!');
            setDialogContent('Successfully created new bot.');
            toggleLoading(false);
        } catch (err) {
            if (err.response.data?.errorMessage) {
                setDialogContent(err.response.data.errorMessage);
            } else {
                setDialogTitle('Uh oh');
                setDialogContent('Looks like something went wrong, if this keeps happening call Hunter');
            }
            toggleLoading(false);
        }
    }
    const submitForm = () => {
        setDialogTitle('Sending info along');
        toggleLoading(true);
        setDialogContent('Creating the new bot');
        createNewBot();
        toggleLoadingDialog(true);
    }


    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Stack spacing={3} sx={{ width: '100%' }}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField value={product} onChange={(e) => { setProduct(e.target.value) }} fullWidth label='Product Name' />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField value={minPrice} type='number' onChange={(e) => { setMinPrice(e.target.value) }} fullWidth label='Min Price' />
                    <TextField value={maxPrice} type='number' onChange={(e) => { setMaxPrice(e.target.value) }} fullWidth label='Max Price' />
                    <TextField value={maxQuantity} type='number' onChange={(e) => { setMaxQuantity(e.target.value) }} fullWidth label='Max Quantity' />
                    <TextField value={maxTotal} type='number' onChange={(e) => { setMaxTotal(e.target.value) }} fullWidth label='Max Total' />
                </Stack>
                <Stack spacing={3}>
                    <Button
                        fullWidth
                        style={{ marginTop: '20px' }}
                        variant='contained'
                        onClick={submitForm}
                    >Submit</Button>
                </Stack>
            </Stack>
            <Dialog open={loadingDialog} onClose={() => { toggleLoadingDialog(false) }} fullWidth maxWidth='sm'>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <Typography variant='subtitle1' component='div'>
                        {dialogContent}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' disabled={loading} onClick={() => {
                        toggleLoadingDialog(false);
                        closeForm();
                    }}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default NewBotForm;