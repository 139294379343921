import React, { Component } from 'react';
import { TableCell, Button, TableRow, Table, TableHead, TableBody } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { ExpandableTableRow } from '../../universalComponents';
import ExpandedRowComponent from './botResultExpandedRow';
import quickLinks from '../../../utils/quickLinks';
import dayjs from 'dayjs';

export default class BotResultsComponent extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      botID: null,
      results: []
    };
  }

  addResultToTable = (value) => {
    if (value.botID === this.state.botID) this.setState({ results: [...value.results, ...this.state.results] })
  }
  componentDidMount() {
    const { botResults, botID } = this.props;
    this.setState({ results: botResults, botID });
  }

  render() {
    const { openInNewTab } = quickLinks();
    return (
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox' />
                <TableCell align='left'>Price</TableCell>
                <TableCell align='left'>Item Name</TableCell>
                <TableCell align='left'>Condition</TableCell>
                <TableCell align='left'>Created At</TableCell>
                <TableCell padding='checkbox' />
              </TableRow>
            </TableHead>
            <TableBody>
            {
              this.state.results.map(result => (
                <ExpandableTableRow
                  key={`result_${result._id}`}
                  expandComponent={<ExpandedRowComponent row={result} />}
                >
                  <TableCell align='left'>${result.price.allIn?.toFixed(2) || '--'}</TableCell>
                  <TableCell align='left'>{result.itemName || '--'}</TableCell>
                  <TableCell align='left'>{result.condition || '--'}</TableCell>
                  <TableCell align='right'>{result.createdAt ? dayjs(result.createdAt).format('MM/DD/YY, HH:mm') : '--'}</TableCell>
                  <TableCell align='left'>
                    <Button onClick={() => openInNewTab(`https://www.ebay.com/itm/${result.itemID}`)} variant='outlined' startIcon={<OpenInNew />}>
                      Open
                    </Button>
                  </TableCell>
                </ExpandableTableRow>
              ))
            }
            </TableBody>
          </Table>
          <div style={{ display: 'flex', justifyContent: 'center', color: 'rgba(0,0,0,0.5)' }}>{ !this.state.results.length ? 'no results yet' : null }</div>
        </div>
    );
  }
};
