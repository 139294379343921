const Footer = () => {
  return (
    <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center' }}>
      <div style={{ paddingBottom: '5px' }}>
        <img alt='logo' style={{ width: '45px' }} src={require('../../assets/logo.png')} />
      </div>
    </div>
  )
}

export default Footer;
